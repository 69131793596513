<template>
    <div class="w-full">
        <section-title 
            fontSmall
            title="Office Information"
        >
            <p class="text-sm font-medium text-neutral-600">Please fill out all the required fields with the red asterisk (<span class="text-red-600">*</span>)</p>
        </section-title>
        <form class="grid grid-cols-2 gap-6 mt-6">
            <div class="col-span-2 border-t border-gray-200"></div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Office Name"
                    placeholder="Office name"
                    name="office_name"
                    id="office_name"
                    v-model="form.name"
                    :error="errors?.name"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-2">
                <file-input
                    id="office_logo"
                    name="office_logo"
                    label="Office Logo"
                    :trailingIcon="form.logo ? XMarkIcon : LinkIcon"
                    :trailingIconClass="form.logo ? 'pointer-events-auto' : 'pointer-events-none'"
                    @change="handleImagePreview"
                    @iconClick="openLink()"
                    :error="errors?.logo"
                    :disabled="!hasPermission('can-update-department')"
                />
                <img
                    class="mt-2 max-h-[300px] border border-gray-200 rounded p-2"
                    v-if="preview"
                    :src="preview"
                    alt="Image Preview"
                    :disabled="!hasPermission('can-update-department')"
                />

                <img
                    class="mt-2 max-h-[300px] border border-gray-200 rounded p-2"
                    v-else-if="!preview && form.file_url"
                    :src="form.file_url"
                    alt="Image Preview"
                />
            </div>
            <div class="col-span-1">
                <text-input
                    type="text"
                    label="Prefix"
                    placeholder="Prefix"
                    name="prefix"
                    id="prefix"
                    v-model="form.prefix"
                    :error="errors?.prefix"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-1">
                <text-input
                    type="tel"
                    label="Contact Number"
                    placeholder="contact number"
                    name="contact_number"
                    id="contact_number"
                    :add_on_left="true"
                    add_on_text="+63"
                    :maxlength="10"
                    v-model="form.contact_number"
                    :error="errors?.contact_number"
                    @keypress="numberonly"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Address"
                    placeholder="address"
                    name="address"
                    id="address"
                    v-model="form.address"
                    :error="errors?.address"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>

            <div class="col-span-1">
                <multi-select 
                    searchable
                    :options="regionOptions"
                    id="region"
                    name="region"
                    label="Region"
                    placeholder="Select.."
                    v-model="form.region_id"
                    :error="errors?.region_id"
                    @update:modelValue="regionChange()"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-1">
                <multi-select 
                    searchable
                    :options="provinceOptions"
                    id="province"
                    name="province"
                    label="Province"
                    placeholder="Select.."
                    v-model="form.province_id"
                    :error="errors?.province_id"
                    @update:modelValue="provinceChange()"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-1">
                <multi-select 
                    searchable
                    :options="cityOptions"
                    id="city_municipality"
                    name="city_municipality"
                    label="City/Municipality/District"
                    placeholder="Select.."
                    v-model="form.city_id"
                    :error="errors?.city_id"
                    @update:modelValue="cityChange()"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-1">
                <multi-select 
                    searchable
                    :options="barangayOptions"
                    id="barangay"
                    name="barangay"
                    label="Barangay"
                    placeholder="Select.."
                    v-model="form.barangay_id"
                    :error="errors?.barangay_id"
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>
            <div class="col-span-2">
                <multi-select searchable
                    :options="statusOptions"
                    id="status"
                    name="status"
                    label="Status"
                    placeholder="Select.."
                    v-model="form.status"
                    :error="errors?.status"
                    required
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="ISO For Intake PDF Form"
                    placeholder="Intake ISO"
                    name="iso_intake"
                    id="iso_intake"
                    v-model="form.iso_intake"
                    :error="errors?.iso_intake"
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="ISO For Patient Profile PDF Form"
                    placeholder="Patient Profile ISO"
                    name="iso_patient_profile"
                    id="iso_patient_profile"
                    v-model="form.iso_patient_profile"
                    :error="errors?.iso_patient_profile"
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="ISO For Safety Assessment PDF Form"
                    placeholder="Safety Assessment ISO"
                    name="iso_assessment"
                    id="iso_assessment"
                    v-model="form.iso_assessment"
                    :error="errors?.iso_assessment"
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="ISO For SCAR PDF Form"
                    placeholder="SCAR ISO"
                    name="iso_scar"
                    id="iso_scar"
                    v-model="form.iso_scar"
                    :error="errors?.iso_scar"
                    :disabled="!hasPermission('can-update-department')"
                />
            </div>

            <slot></slot>
        </form>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import { numberonly } from "@/components/composables/useNumberOnly";

import { OfficeDataInterface, SelectInterface} from "@/interfaces/pages/offices/OfficeInterface"
import FileInput from "@/components/inputs/FileInput.vue";
import { XMarkIcon, LinkIcon } from "@heroicons/vue/24/outline";
import DropdownService from "@/classes/Dropdowns/DropdownService";
import { hasPermission } from "@/classes/AuthService";

const props = defineProps({
    form: {
        type: Object as () => OfficeDataInterface,
        defualt: [],
        required: true,
    },
    errors: {
        type: Object,
        defualt: [],
        required: true,
    },
});

const emit = defineEmits(['update:location']);

const statusOptions = [
    { id: 0, label: 'Inactive', value: 0},
    { id: 1, label: 'Active', value: 1 }
]

const form = ref<OfficeDataInterface>(props.form);
const regionOptions = ref<SelectInterface>([])
const provinceOptions = ref<SelectInterface>([])
const cityOptions = ref<SelectInterface>([])
const barangayOptions = ref<SelectInterface>([])

const preview = ref<string|null>(null);
const logo = ref<string|null>(null);

const handleImagePreview = (event) => {
  const input = event.target;
  if (input.files) {
    const reader = new FileReader();
    reader.onload = (e) => {
      preview.value = e.target.result;
    };
    logo.value = input.files[0];
    form.value.logo = input.files[0];
    console.log(form.value.logo);
    reader.readAsDataURL(logo.value);
  }
};

const openLink = (): void => {
    if (form.value.logo != '') {
        preview.value = '';
        form.value.logo = '';
    }
}

const getRegions = ():void => {
    DropdownService
        .regions()
        .then(({data}) => {
            regionOptions.value = data;
        })
}

const getProvince = ():void => {
    if(form.value.region_id){
        DropdownService
            .provinces(form.value.region_id)
            .then(({data}) => {
                provinceOptions.value = data;
            })
    }
}

const getCity = ():void => {
    if(form.value.province_id){
        DropdownService
            .cities(form.value.province_id)
            .then(({data}) => {
                cityOptions.value = data;
            })
    }
}

const getBrangay = ():void => {
    if(form.value.city_id){
        DropdownService
            .barangays(form.value.city_id)
            .then(({data}) => {
                barangayOptions.value = data;
            })
    }
}

const regionChange = ():void => {
    form.value.city_id = null
    form.value.barangay_id = null
    form.value.province_id = null
    getProvince()
}

const provinceChange = ():void => {
    form.value.city_id = null
    form.value.barangay_id = null
    getCity()
}

const cityChange = ():void => { 
    form.value.barangay_id = null
    getBrangay()
}

onMounted(() => {
    getRegions()

    if(form.value.id){
        getProvince();
        getCity();
        getBrangay();
    }
})
</script>